import {ApiService} from "./ApiService";

const API = {
  BASE: '/api/stats'
};

export const StatsService = {
  getAll() {
    return ApiService.get(API.BASE);
  },
  get(year) {
    return ApiService.get(API.BASE, year);
  }
}
