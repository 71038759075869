import {useContext} from "react";
import {AppContext} from "../App";

export default function AuthWrapper(props) {

  const {authorized} = useContext(AppContext);

  return authorized ? props.children : '';

}
