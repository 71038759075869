import {Badge} from "primereact/badge";

export default function RankingChange(props) {

  const getColor = (type) => {
    if (type === "UP") return '#6aa84f';
    if (type === "DOWN") return '#e06666';
    if (type === "NEW") return '#4a99fa';
    if (type === "NONE") return '#969696';
  }

  const formatValue = (type, value) => {
    if (type === "UP") return "+" + value;
    if (type === "DOWN") return value;
    if (type === "NEW") return "N";
    if (type === "NONE") return "0";
  }

  return <Badge value={formatValue(props.type, props.value)} style={{background: getColor(props.type), width: "3rem", borderRadius: "10px"}}/>

}
