import {ApiService} from "./ApiService";

const API = {
  BASE: '/api/games',
  FULL: '/api/games/full',
  NAMES_AND_COOP_MODE: '/api/games/namesAndModes',
  GALLERY: '/api/games/gallery',
  COVER: '/api/games/cover',
  LAST_UPDATE: '/api/games/lastUpdate'
};

export const GameService = {
  getAll() {
    return ApiService.get(API.FULL);
  },
  getNamesAndModes() {
    return ApiService.get(API.NAMES_AND_COOP_MODE);
  },
  getGallery() {
    return ApiService.get(API.GALLERY);
  },
  get(id) {
    return ApiService.get(API.BASE, id);
  },
  getCover(id) {
    return ApiService.get(API.COVER, id);
  },
  getLastUpdate() {
    return ApiService.get(API.LAST_UPDATE);
  },
  save(game) {
    return ApiService.save(API.BASE, game);
  },
  delete(id) {
    return ApiService.delete(API.BASE, id);
  }
}
