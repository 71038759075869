import {useEffect, useRef, useState} from 'react';
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {DataLoadingIndicator} from "../../common/DataLoadingIndicator";
import {isSmallScreenVertical, updatePageTitle} from "../../utilities/CommonUtils";
import AuthWrapper from "../../common/AuthWrapper";
import {TabPanel, TabView} from "primereact/tabview";
import {Accordion, AccordionTab} from "primereact/accordion";
import {AdminService} from "../../service/AdminService";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";

export default function Admin() {
  const title = 'Panel administracyjny';
  const toast = useRef(null);
  const [alerts, setAlerts] = useState([]);
  const [players, setPlayers] = useState([]);
  const [playerUpdateData, setPlayerUpdateData] = useState({oldName: '', newName: ''});
  const warningIcon = "pi pi-exclamation-circle ms-1";
  const okIcon = "pi pi-check-circle ms-1";

  useEffect(() => {
    updatePageTitle(title);
  }, []);

  useEffect(() => {
    AdminService.getAlerts().then(data => setAlerts(data));
    AdminService.getDistinctPlayers().then(data => setPlayers(data));
  }, []);

  const headerTemplate = () => {
    return <div className={"p-panel-header bg-white" + (isSmallScreenVertical() ? " d-flex flex-column" : "")}>
      <h3>{title}</h3>
    </div>
  }

  const showSuccessToast = (message) => toast.current.show({severity: 'success', summary: 'Sukces!', detail: message});
  const showFailureToast = (message) => toast.current.show({severity: 'error', summary: 'Błąd!', detail: message});

  return <AuthWrapper>
    <Panel className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content admin-panel-content"}}}>
      <BlockUI blocked={alerts.length === 0} template={<DataLoadingIndicator/>} containerClassName="flex-grow-1">
        <Toast ref={toast} position="center" className="text-center"/>
        <TabView>
          <TabPanel header="Alerty" rightIcon={alerts.filter(alert => alert.warnings.length > 0).length > 0 ? warningIcon : okIcon}>
            <Accordion>
              {alerts.map(alert =>
                <AccordionTab key={alert.name} disabled={alert.warnings.length === 0} style={{whiteSpace: "pre"}}
                              headerTemplate={<span>{alert.name}<span className={alert.warnings.length > 0 ? warningIcon : okIcon}/></span>}>
                  <table className="text-wrap">
                    <tbody>
                    {alert.warnings.map(warning => <tr key={warning.name} className="border-bottom">
                      <td className="fw-bold">{warning.name}</td>
                      <td>{warning.warnings.join(", ")}</td>
                    </tr>)}
                    </tbody>
                  </table>
                </AccordionTab>
              )}
            </Accordion>
          </TabPanel>
          <TabPanel header="Narzędzia">
            <Accordion>
              <AccordionTab header="Zmiana nazwy gracza">
                <div className={isSmallScreenVertical() ? "d-flex flex-column align-items-center gap-2" : ""}>
                  <Dropdown options={players} value={playerUpdateData.oldName} placeholder="Wybierz gracza..."
                            onChange={event => setPlayerUpdateData({oldName: event.value, newName: playerUpdateData.newName})}/>
                  <span className="ms-2 me-2">zmień na</span>
                  <InputText value={playerUpdateData.newName} placeholder="Nowa nazwa..."
                             onChange={event => setPlayerUpdateData({oldName: playerUpdateData.oldName, newName: event.target.value})}/>
                  <Button label="Zapisz" disabled={!playerUpdateData.oldName || !playerUpdateData.newName} className="ms-2"
                          onClick={() => AdminService.updatePlayer(playerUpdateData).then(response => {
                            if (response.ok) {
                              showSuccessToast('Zmiany zostały zapisane.');
                              const _players = [...players];
                              _players[_players.indexOf(playerUpdateData.oldName)] = playerUpdateData.newName;
                              setPlayers(_players);
                              setPlayerUpdateData({oldName: '', newName: ''});
                            } else {
                              response.text().then(error => showFailureToast('Zmiana nie została zapisana. ' + error));
                            }
                          })}/>
                </div>
              </AccordionTab>
            </Accordion>
          </TabPanel>
        </TabView>
      </BlockUI>
    </Panel>
  </AuthWrapper>
}
