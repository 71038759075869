import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {useContext, useRef} from 'react';
import {Toolbar} from "primereact/toolbar";
import {Menu as PrimeMenu} from "primereact/menu";
import AuthWidget from "./AuthWidget";
import {isSmallScreen} from "../utilities/CommonUtils";
import {AppContext} from "../App";

export default function Menu() {

  const compactMenuRef = useRef(null);
  const {authorized} = useContext(AppContext);

  const menuItems = [
    {
      label: 'Galeria',
      icon: 'pi pi-images',
      url: '/',
      visible: true
    },
    {
      label: 'Lista',
      icon: 'pi pi-list',
      url: '/games',
      visible: true
    },
    {
      label: 'Historia',
      icon: 'pi pi-calendar',
      url: '/history',
      visible: true
    },
    {
      label: 'Poczekalnia',
      icon: 'pi pi-clock',
      url: '/waitlist',
      visible: true
    },
    {
      label: 'Statystyki',
      icon: 'pi pi-chart-pie',
      url: '/stats',
      visible: true
    },
    {
      label: 'Rankingi',
      icon: 'pi pi-chart-line',
      url: '/ranking',
      visible: true
    },
    {
      label: 'Administracja',
      icon: 'pi pi-cog',
      url: '/admin',
      visible: authorized
    }
  ];

  const menuItemLink = menuItem => menuItem.visible && <Link to={menuItem.url} key={menuItem.label}>
    <Button label={menuItem.label} icon={menuItem.icon} className="m-1"/>
  </Link>;

  const standardMenu = <div>
    {menuItems.map(menuItemLink)}
  </div>

  const compactMenu = <div>
    <Button label="Menu" icon="pi pi-home" onClick={event => compactMenuRef.current.toggle(event)}/>
    <PrimeMenu ref={compactMenuRef} popup={true} model={menuItems}/>
  </div>;

  const authWidget = <AuthWidget/>;

  return <Toolbar start={isSmallScreen() ? compactMenu : standardMenu} end={authWidget}/>
}