import {ApiService} from "./ApiService";

const API = {
  BASE: '/api/waitlist',
  BOUGHT: '/api/waitlist/bought',
  COVER: '/api/waitlist/cover'
};

export const WaitListService = {
  getAll() {
    return ApiService.get(API.BASE);
  },
  get(id) {
    return ApiService.get(API.BASE, id);
  },
  getCover(id) {
    return ApiService.get(API.COVER, id);
  },
  save(game) {
    return ApiService.save(API.BASE, game);
  },
  delete(id) {
    return ApiService.delete(API.BASE, id);
  },
  bought(id, source) {
    return fetch(API.BOUGHT + '/' + id, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: source
    });
  }
}
