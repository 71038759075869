export const TOP_LIST_COLORS = ['#ffd700', '#c0c0c0', '#cd7f32'];

export const SECOND = 1000;

export const YEAR_START = 2022;

export const COVER_MAX_FILE_SIZE = 100 * 1024;

export const COVER_MAX_DIMENSIONS = 360;

export const RATINGS = {
  5: "Na pewno chcę to mieć w kolekcji",
  4.5: "Wiele rzeczy w tej grze mi się podoba - wysoki priorytet",
  4: "Ciekawa, wnosi coś świeżego do naszej kolekcji, będzie grana",
  3.5: "Dobra gra, chętnie wrócę co jakiś czas",
  3: "Ani dobra ani zła, można zagrać",
  2: "Raczej słaba, rzadko będzie trafiać na stół",
  1: "Absolutnie nie chcę tej gry, nie podoba mi się"
}