import Rating from "./Rating";
import {RATINGS} from "../utilities/Constants";

export default function Legend() {

  return <div className="legend">
    <div><Rating value={5}/></div>
    <div>{RATINGS[5]}</div>
    <div><Rating value={4.5}/></div>
    <div>{RATINGS[4.5]}</div>
    <div><Rating value={4}/></div>
    <div>{RATINGS[4]}</div>
    <div><Rating value={3.5}/></div>
    <div>{RATINGS[3.5]}</div>
    <div><Rating value={3}/></div>
    <div>{RATINGS[3]}</div>
    <div><Rating value={2}/></div>
    <div>{RATINGS[2]}</div>
    <div><Rating value={1}/></div>
    <div>{RATINGS[1]}</div>
  </div>

}
