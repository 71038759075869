import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Panel} from "primereact/panel";
import {isSmallScreenVertical, updatePageTitle} from "../../utilities/CommonUtils";
import {useParams} from "react-router-dom";
import {OrderList} from "primereact/orderlist";
import {Button} from "primereact/button";
import {RankingService} from "../../service/RankingService";
import {Toast} from "primereact/toast";
import {TabPanel, TabView} from "primereact/tabview";
import {InputTextarea} from "primereact/inputtextarea";
import {AppContext} from "../../App";
import {SECOND} from "../../utilities/Constants";

export default function RankingEdit() {
  const title = 'Ranking';
  const toastCenter = useRef(null);
  const toastBottomRight = useRef(null);
  const {year} = useParams();
  const [games, setGames] = useState([]);
  const {person} = useContext(AppContext);

  useEffect(() => {
    updatePageTitle(title + ' ' + year);
  }, [year]);

  useEffect(() => {
    if (person) {
      RankingService.get(year + '/games/' + person).then(data => setGames(data));
    }
  }, [year, person]);

  const performSave = useCallback((auto) => {
    const ranking = {
      year: year,
      author: person,
      games: games
    };
    const autoString = auto ? " automatycznie" : "";
    const toastRef = auto ? toastBottomRight : toastCenter;
    RankingService.save(ranking).then(response => {
      if (response.ok) {
        showSuccessToast(toastRef, "Ranking został zapisany" + autoString + ".")
      } else {
        response.text().then(error => showFailureToast(toastRef, 'Ranking nie został zapisany' + autoString + '. ' + error));
      }
    });
  }, [year, person, games]);

  useEffect(() => {
    if (person) {
      const autosaveInterval = setInterval(() => {
        performSave(true);
      }, 5 * 60 * SECOND);
      return () => clearInterval(autosaveInterval);
    }
  }, [person, performSave]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    performSave(false);
  };

  const showSuccessToast = (toastRef, message) => toastRef.current.show({
    severity: 'success',
    summary: 'Sukces!',
    detail: message
  });

  const showFailureToast = (toastRef, message) => toastRef.current.show({
    severity: 'error',
    summary: 'Błąd!',
    detail: message
  });

  const headerTemplate = () => {
    return <div className="p-panel-header bg-white">
      <h3>{title + ' ' + year}</h3>
    </div>
  }

  return <>
    <Panel className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content ranking-edit-panel-content"}}}>
      <Toast ref={toastCenter} position="center" className="text-center"/>
      <Toast ref={toastBottomRight} position="bottom-right" className="text-center"/>
      <div className="flex-grow-1 d-flex flex-column align-items-center gap-3">
        <span>Ranking według: {person}</span>
        <TabView>
          <TabPanel header="Lista">
            <OrderList value={games} onChange={event => setGames(event.value)} dragdrop={true} listStyle={{maxHeight: "50vh"}}/>
          </TabPanel>
          <TabPanel header="Tekst">
            <InputTextarea value={games.join("\n")} onChange={event => setGames(event.target.value.split("\n"))}
                           cols={isSmallScreenVertical() ? 20 : 40} rows={20}/>
          </TabPanel>
        </TabView>
        <Button icon="pi pi-save" label="Zapisz" onClick={handleSubmit}/>
      </div>
    </Panel>
  </>
}
