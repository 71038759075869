import {ApiService} from "./ApiService";

const API = {
  BASE: '/api/ranking'
};

export const RankingService = {
  get(year) {
    return ApiService.get(API.BASE, year);
  },
  save(ranking) {
    return ApiService.save(API.BASE, ranking);
  }
}
