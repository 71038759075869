import {Badge} from "primereact/badge";
import chroma from "chroma-js";
import {RATINGS} from "../utilities/Constants";

export default function Rating(props) {

  const getRatingColor = (rating) => {
    const colorScale = chroma.scale(['#e06666', '#e2d98b', '#6aa84f']).domain([1, 5]);
    return colorScale(rating);
  }

  const getTooltipContent = (rating) => {
    if (RATINGS[rating]) {
      return rating + ": " + RATINGS[rating];
    }
    const possibleRatings = Object.keys(RATINGS).map(key => parseFloat(key)).sort();
    for (let i = 0; i < possibleRatings.length - 1; i++) {
      const lower = possibleRatings[i];
      const higher = possibleRatings[i + 1];
      if (rating > lower && rating < higher) {
        return lower + ": " + RATINGS[lower] + "<br/>" + higher + ": " + RATINGS[higher];
      }
    }
  };

  return <Badge value={props.value} style={{background: getRatingColor(props.value), width: "3rem", borderRadius: "10px"}}
                data-tooltip-id={props.tooltipId} data-tooltip-html={getTooltipContent(props.value)}/>

}
