import {useContext} from 'react';
import {Button} from "primereact/button";
import {AppContext} from '../App';

export default function AuthWidget() {

  const {authenticated} = useContext(AppContext);
  const {email} = useContext(AppContext);
  const {appVersion} = useContext(AppContext);

  const logoutClickHandler = () => {
    window.location.href = process.env.REACT_APP_API_URL + '/logout?redirectAfterLogout=' + window.location.pathname;
  };

  const loginClickHandler = () => {
    window.location.href = process.env.REACT_APP_API_URL + '/oauth2/authorization/google?redirectAfterLogin=' + window.location.pathname;
  };

  return <>{authenticated ?
    <Button label="Wyloguj" icon="pi pi-user" onClick={logoutClickHandler} tooltip={`Zalogowano jako ${email}`} tooltipOptions={{position: "left"}}/> :
    <Button label="Zaloguj" icon="pi pi-google" onClick={loginClickHandler}/>}
    <Button icon="pi pi-info-circle" className="m-1" tooltip={`Planszówki wersja ${appVersion}`} tooltipOptions={{position: "bottom", event: "focus"}}/>
  </>
}
