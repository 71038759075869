import {COVER_MAX_DIMENSIONS} from "./Constants";
import {isSmallScreen, isSmallScreenHorizontal, isSmallScreenVertical} from "./CommonUtils";
import {Link} from "react-router-dom";

export const SMALL_BOX_SCALE = 0.75;

export const BIG_BOX_SCALE = 1;

const GALLERY_MARGIN = isSmallScreen() ? 10 : 20;

const BORDER = 1;

const getGalleryContainer = () => document.getElementById("galleryContainer");

const calculateTargetHeight = () => {
  if (isSmallScreenVertical()) {
    return getGalleryContainer().offsetWidth * 0.67;
  } else if (isSmallScreenHorizontal()) {
    return getGalleryContainer().offsetWidth * 0.25;
  } else {
    return COVER_MAX_DIMENSIONS;
  }
};

export const resizeCover = (size, scale, globalScale) => {
  const width = size.width;
  const height = size.height;
  const targetHeight = calculateTargetHeight();
  let ratio = height / (targetHeight * scale * globalScale);
  return {width: width / ratio, height: height / ratio};
};

export const generateGallery = (allCovers) => {
  let _temporaryCovers = [];
  const sortedCovers = [];
  for (let i = 0; i < allCovers.length; i++) {
    const cover = allCovers[i];
    const galleryItem = <div
      style={{
        marginBottom: GALLERY_MARGIN + "px",
        marginRight: GALLERY_MARGIN + "px"
      }}
      className="cover-shadow"
      key={"cover" + i}><Link to={"/games/go/" + cover.props["data-id"]}>{cover}</Link></div>;
    _temporaryCovers.push({idx: i, width: Math.ceil(cover.props.width + GALLERY_MARGIN + 2 * BORDER), galleryItem: galleryItem});
  }
  const galleryContainer = getGalleryContainer();
  const computedStyle = getComputedStyle(galleryContainer);
  const containerCapacity = galleryContainer.offsetWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight) - GALLERY_MARGIN - 2 * BORDER;
  const rows = [];
  while (_temporaryCovers.length > 0) {
    const row = fillGalleryRow(_temporaryCovers, containerCapacity);
    if (row.covers.length === 0) {
      break;
    }
    rows.push(row);
    for (let i = 0; i < row.covers.length; i++) {
      const cover = row.covers[i];
      _temporaryCovers = _temporaryCovers.filter(item => item.idx !== cover.idx);
    }
  }
  rows.sort((a, b) => b.width - a.width);
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    for (let j = 0; j < row.covers.length; j++) {
      sortedCovers.push(row.covers[j].galleryItem);
    }
  }
  sortedCovers.push(..._temporaryCovers.map(r => r.galleryItem));
  return sortedCovers;
};

const fillGalleryRow = (covers, capacity) => {
  let resultSum = 0;
  let resultCovers = [];
  covers.sort((a, b) => a.width - b.width);
  for (let i = 0; i < covers.length; i++) {
    let currentSum = 0;
    let currentCovers = [];
    for (let j = i; j < covers.length; j++) {
      currentSum += covers[j].width;
      currentCovers.push(covers[j]);
      if (currentSum <= capacity) {
        if (currentSum > resultSum) {
          resultSum = currentSum;
          resultCovers = [...currentCovers];
        }
      }
    }
  }
  return {width: resultSum, covers: resultCovers};
};