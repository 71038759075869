import {ApiService} from "./ApiService";

const API = {
  BASE: '/api/history',
};

export const HistoryService = {
  getAll() {
    return ApiService.get(API.BASE);
  },
  get(id) {
    return ApiService.get(API.BASE, id);
  },
  save(history) {
    return ApiService.save(API.BASE, history);
  },
  delete(id) {
    return ApiService.delete(API.BASE, id);
  }
}
